<template>
  <v-card class="card text-center font-family-raleway-medium">
    <h3 class="card-text mb-5">
      {{ $t("create_campaign_page.review_campaign_step_title") }}
    </h3>
    <v-dialog v-model="update" class="dialog" max-width="600">
      <v-card class="card">
        <v-form ref="form" v-model="formTitleValid">
          <h3 class="text-center font-family-raleway-medium mb-5">
            {{ title }}
          </h3>
          <v-text-field
            class="input-border mt-2"
            v-model="valueToEdit"
            :rules="nameRules"
            :maxlength="100"
            counter="100"
            height="60"
            outlined
            required
          />
          <v-row class="pb-2">
            <v-col class="text-right mt-2" cols="12">
              <v-btn
                class="mr-5"
                style="box-shadow: none"
                @click="handleClickCancelUpdate"
                >{{ $t("cancel") }}
              </v-btn>
              <v-btn
                class="button save-btn"
                height="42"
                @click="handleSaveCampaignInfos"
                >{{ $t("save_button") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <div class="pt-0 mt-0">
      <v-list-item class="text-left pt-4 px-0 d-block">
        <v-list-item-title class="align-center d-flex">
          <img :src="imgCampaignName" height="17" width="17" />
          <h4 class="ml-5 review-titles">
            {{ $t("create_campaign_page.campaign_name") }}
          </h4>
        </v-list-item-title>
        <v-list-item-title class="mt-2 ml-10 d-flex align-center">
          <span class="review-description">{{ campaign.name }}</span>
          <v-spacer></v-spacer>
          <v-btn class="edit-btn" @click="openChangeNameModal">
            <v-icon class="mr-2" small>mdi-pencil-outline</v-icon>
            {{ $t("edit_button") }}
          </v-btn>
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="text-left pt-4 px-0 d-block">
        <v-list-item-title class="align-center d-flex">
          <img :src="urlImg" height="17" width="17" />
          <strong class="ml-5 review-titles">{{
            $t("create_campaign_page.campaign_url")
          }}</strong>
        </v-list-item-title>
        <v-list-item-title class="mt-2 ml-10 d-flex align-center">
          <span class="review-description">{{ campaign.url }}</span>
          <v-spacer></v-spacer>
          <v-btn
            class="edit-btn"
            @click="
              handleEdit({
                val: campaign.url,
                index: 'url',
                title: $t('create_campaign_page.campaign_url'),
              })
            "
          >
            <v-icon class="mr-2" small>mdi-pencil-outline</v-icon>
            {{ $t("edit_button") }}
          </v-btn>
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="text-left pt-4 px-0 d-block">
        <v-list-item-title class="align-center d-flex">
          <img :src="phone" height="17" width="17" />
          <strong class="ml-5 review-titles">{{
            $t("create_campaign_page.phone_number")
          }}</strong>
        </v-list-item-title>
        <v-list-item-title class="mt-2 ml-10 d-flex align-center">
          <span class="review-description">{{ campaign.phoneNumber }}</span>
          <v-spacer></v-spacer>
          <v-btn
            class="edit-btn"
            @click="
              handleEdit({
                val: campaign.phone,
                index: 'phone',
                title: $t('create_campaign_page.phone_number'),
              })
            "
          >
            <v-icon class="mr-2" small>mdi-pencil-outline</v-icon>
            {{ $t("edit_button") }}
          </v-btn>
        </v-list-item-title>
      </v-list-item>

      <!--        keywords-->
      <v-list-item class="text-left pt-4 px-0 d-block">
        <div class="align-center d-flex">
          <img :src="keyword" height="17" width="17" />
          <strong class="ml-5 review-titles">{{
            $t("create_campaign_page.keywords")
          }}</strong>
        </div>
        <div class="mt-2 ml-10">
          <span
            class="chip my-1 review-description"
            v-for="(item, index) of campaign.keywords"
            :key="item"
            style="font-size: 16px"
          >
            {{item}}
            <span v-if="index !== campaign.keywords.length - 1">,</span>
          </span>
          <v-spacer></v-spacer>
        </div>
      </v-list-item>

      <v-list-item class="text-left pt-4 px-0 d-block">
        <v-list-item-title class="align-center d-flex">
          <img :src="goals" height="17" width="17" />
          <strong class="ml-5 review-titles">{{
            $t("create_campaign_page.campaign_goals")
          }}</strong>
        </v-list-item-title>
        <v-list-item-title
          class="mt-2 ml-10 d-flex align-center review-description"
        >
          <div>
            <p v-if="campaign.goals.getCalls" class="pb-0 mb-0">
              {{ $t(getCalls) }}
            </p>
            <p v-if="campaign.goals.getOnlineSales" class="pb-0 my-2">
              {{ $t(getOnlineSales) }}
            </p>
            <p v-if="campaign.goals.getOfflineVisits" class="pb-0 mb-0">
              {{ $t(getOfflineVisits) }}
            </p>
          </div>
          <v-spacer></v-spacer>
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="text-left pt-4 pl-0 pr-0 d-block">
        <v-list-item-title class="align-center d-flex">
          <img :src="location" height="17" width="17" />
          <strong class="ml-5 review-titles">{{
            $t("create_campaign_page.location")
          }}</strong>
        </v-list-item-title>
        <v-list-item-title class="mt-2 ml-10 d-flex align-center">
          <span class="review-description">
            {{ campaign.campaignTargets.country }}
          </span>
          <v-spacer></v-spacer>
          <v-btn
            class="edit-btn ml-5"
            @click="isOpenChangeLocationModal = true"
          >
            <v-icon class="mr-2" small>mdi-pencil-outline</v-icon>
            {{ $t("edit_button") }}
          </v-btn>
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="text-left mt-4 pl-0 d-block vision">
        <v-list-item-title class="align-center d-flex">
          <img :src="addsIcon" height="17" width="17" />
          <h4 class="ml-5 review-titles">
            {{ $t("create_campaign_page.your_ads") }}
          </h4>
          <v-spacer />
          <!--          <v-col :md="$i18n.locale === 'En' ? 2 : 3">-->
          <v-btn
            class="edit-btn mobile-edit-btn"
            @click="handleclickEditOptions"
          >
            <v-icon class="mr-2" small>mdi-pencil-outline</v-icon>
            {{ $t("edit_button") }}
          </v-btn>
          <!--          </v-col>-->
        </v-list-item-title>
        <v-divider class="my-3" />
        <div
          class="d-flex justify-space-between align-center ml-10"
          v-if="getIsError"
        >
          <span
            class="font-family-raleway-medium text-left"
            style="font-size: 14px; color: red"
            >{{ $t("create_campaign_page.make_sure") }}</span
          >
        </div>
        <div v-if="formAdOptions">
          <v-form class="text-left">
            <h5 class="font-family-raleway-medium">
              {{ $t("create_campaign_page.headlines") }}
            </h5>
            <div v-for="(item, index) of headlines" :key="index">
              <input
                v-model="item.title"
                class="input-border textarea-style mt-2 pa-3"
                :class="
                  item.title.length > 30 ||
                  isHaveSameContent(item, index, 'headlines') ||
                  item.title.length === 0
                    ? 'error-border'
                    : ''
                "
              />
              <v-row
                class="ma-0 d-flex justify-space-between font-family-raleway-medium px-2"
              >
                <span
                  style="font-size: 12px; color: red"
                  v-if="item.title.length === 0"
                  >{{ $t("required_text") }}</span
                >
                <span
                  style="font-size: 12px; color: red"
                  v-if="isHaveSameContent(item, index, 'headlines')"
                  >Duplicate assets!</span
                >

                <span style="font-size: 12px; color: red">{{
                  item.title.length > 30
                    ? "Length of headline must not exceed 30 characters"
                    : ""
                }}</span>
                <span
                  :class="item.title.length > 30 ? 'color-red' : ''"
                  style="font-size: 12px"
                  >{{ item.title.length }}/30</span
                >
              </v-row>
            </div>
            <h5 class="font-family-raleway-medium">
              {{ $t("create_campaign_page.descriptions") }}
            </h5>
            <div v-for="(item, index) of descriptions" :key="index">
              <textarea
                v-model="item.title"
                class="input-border textarea-style mt-2 pa-3"
                :class="
                  item.title.length > 90 ||
                  item.title.length === 0 ||
                  isHaveSameContent(item, index, 'descriptions')
                    ? 'error-border'
                    : ''
                "
                :maxlength="90"
                :rows="2"
              />
              <v-row
                class="ma-0 d-flex justify-space-between px-4 font-family-raleway-medium"
              >
                <span
                  style="font-size: 12px; color: red"
                  v-if="item.title.length === 0"
                  >{{ $t("required_text") }}</span
                >

                <span
                  style="font-size: 12px; color: red"
                  v-if="isHaveSameContent(item, index, 'descriptions')"
                  >Duplicate assets!</span
                >
                <span style="font-size: 12px; color: red">{{
                  item.title.length > 90
                    ? "Length of description must not exceed 90 characters"
                    : ""
                }}</span>
                <span
                  :class="item.title.length > 90 ? 'color-red' : ''"
                  style="font-size: 12px"
                  >{{ item.title.length }}/90</span
                >
              </v-row>
            </div>
            <v-row v-if="validatedText !== 'OK' && validatedText !== ''">
              <v-col cols="12">
                <span class="color-red font-family-raleway-medium"
                  >{{ validatedText }}: {{ errorTopic }}</span
                >
                <span class="font-family-raleway-medium" v-if="errorReasons">
                  <br />
                  This the cause of the error
                  <span class="color-red">
                    "<span
                      v-for="item in errorReasons"
                      :key="item"
                      class="color-red"
                      >{{ item }}</span
                    >"
                  </span>
                </span>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="text-right mt-2" cols="12">
                <v-btn
                  class="mr-5 text-capitalize"
                  style="box-shadow: none"
                  @click="handleClickCancel"
                  >{{ $t("cancel") }}
                </v-btn>
                <v-btn
                  class="button save-btn px-10 font-family-raleway-medium"
                  height="42"
                  :loading="isSaving"
                  @click="validateAds"
                  :disabled="getIsError"
                >
                  <strong>{{ $t("save_button") }}</strong>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>

        <v-row v-else class="pl-10 pr-5 mobile-vision">
          <v-col :md="$i18n.locale === 'En' ? 10 : 9">
            <!--headlines-->
            <div class="text-left">
              <span
                class="align-center"
                v-for="(item, index) of headlines"
                :key="index"
              >
                <a
                  :style="
                    index + 1 === headlines.length
                      ? ''
                      : 'border-right: 2px solid #0057FF!important;'
                  "
                  class="font-family-raleway-bold text-decoration-none color-blue"
                  :class="index === 0 ? 'pr-2' : 'px-2'"
                  ><strong>{{ item.title }}</strong></a
                >
              </span>
            </div>
            <!--descriptions-->
            <div class="pt-2 text-left">
              <span
                class="align-center"
                v-for="(item, index) of descriptions"
                :key="index"
              >
                <a
                  :style="
                    index + 1 === descriptions.length
                      ? ''
                      : 'border-right: 1px solid black'
                  "
                  class="text-decoration-none review-description"
                  :class="index === 0 ? 'pr-2' : 'px-2'"
                  >{{ item.title }}</a
                >
              </span>
            </div>
          </v-col>
          <v-col :md="$i18n.locale === 'En' ? 2 : 3">
            <v-btn class="edit-btn float-right" @click="handleclickEditOptions">
              <v-icon class="mr-2" small>mdi-pencil-outline</v-icon>
              {{ $t("edit_button") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item>
    </div>
    <v-row class="align-center" v-if="!formAdOptions">
      <v-col md="5">
        <v-btn
          class="back-btn text-decoration-underline"
          @click="$router.push('approve-edit')"
        >
          {{ $t("back_button") }}
        </v-btn>
      </v-col>
      <v-col md="7" class="pr-2">
        <v-btn
          class="button approve-btn font-family-raleway-bold mb-5 mt-5"
          height="60"
          @click="handleClickSave"
          :loading="isGettingUserInfo"
          :disabled="getIsError"
        >
          {{ $t("create_campaign_page.approve_button") }}
        </v-btn>
      </v-col>
    </v-row>
    <!--      modal for cancel-->
    <cancel-modal
      :cancel="cancel"
      :handleClickCancel="handleClickBack"
      :cancelModal="handleCancelModal"
    />
    <change-campaign-name
      :dialog="isOpenChangeNameModal"
      :defaultName="campaign.name"
      @cancelUpdate="cancelCampaignNameUpdating"
      @saveCampaignName="changeCampaignName"
    />
    <change-location
      :dialog="isOpenChangeLocationModal"
      :locationId="campaign.locationId"
      :countryName="campaign.campaignTargets.country"
      @cancelUpdate="isOpenChangeLocationModal = false"
      @saveCampaignLocation="saveLocationChanges"
    />
  </v-card>
</template>
<script>
import imgCampaignName from "@/assets/img/imgCampaignName.svg";
import urlImg from "@/assets/img/url.svg";
import phone from "@/assets/img/phoneimg.svg";
import keyword from "@/assets/img/keyword.svg";
import goals from "@/assets/img/goals.svg";
import location from "@/assets/img/location.svg";
import addsIcon from "@/assets/img/addsIcon.svg";
import { mapGetters } from "vuex";
import { authService } from "@/services/auth";
import { campaignService } from "@/services/campaign";

export default {
  name: "ReviewCompany",
  components: {
    ChangeLocation: () => import("@/components/dialogs/ChangeLocation"),
    ChangeCampaignName: () => import("@/components/dialogs/ChangeCampaignName"),
    CancelModal: () => import("../MainLanding/Modals/CancelModal"),
  },
  data: () => ({
    update: false,
    cancel: false,
    isGettingUserInfo: false,
    formAdOptions: false,
    isOpenChangeLocationModal: false,
    isSaving: false,
    title: "",
    valueToEdit: "",
    formTitleValid: false,
    nameRules: [(v) => !!v || this.$t("required_text")],
    index: "",
    campaign: {
      name: "",
    },
    imgCampaignName,
    urlImg,
    phone,
    keyword,
    goals,
    addsIcon,
    location,
    form: false,
    isOpenChangeNameModal: false,
    compaignData: {
      companyName: "",
      url: "",
    },
    headlines: [],
    descriptions: [],
    addsData: [],
    selectedItem: {
      headline: "",
      description: "",
    },
    validatedText: "",
    errorTopic: "",
    errorReasons: [],
    getCalls: "create_campaign_page.advertising_goal_step_services1",
    getOnlineSales: "create_campaign_page.advertising_goal_step_services2",
    getOfflineVisits: "create_campaign_page.advertising_goal_step_services2",
  }),
  created() {
    this.setDefaultAdsData();
  },
  computed: {
    ...mapGetters(["getUser"]),
    getNotEditAddData() {
      return this.addsData.filter((item) => !item.isEdit);
    },
    isHaveSameContent: function () {
      return function (item, index, items) {
        const filteredItems = this[items].filter((i, ind) => ind !== index);
        return !!filteredItems.find((i) => i.title === item.title);
      };
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getIsError() {
      if (this.headlines && this.descriptions) {
        const findExistWrongTitle = this.headlines.find(
          (item) => item.title.length > 30 || item.title.length === 0
        );
        const findExistWrongDesc = this.descriptions.find(
          (item) => item.title.length > 90 || item.title.length === 0
        );
        if (findExistWrongTitle || findExistWrongDesc) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    handleclickEditOptions() {
      this.formAdOptions = !this.formAdOptions;
    },
    setDefaultAdsData() {
      const campaign = localStorage.getItem("campaign");
      const parseToObj = JSON.parse(campaign);
      this.campaign = parseToObj;
      this.getAdsData(parseToObj);
    },
    openChangeNameModal() {
      this.isOpenChangeNameModal = true;
    },
    changeCampaignName(val) {
      this.campaign.name = val;
      this.isOpenChangeNameModal = false;
    },
    async handleSaveCampaignInfos() {
      await this.$refs.form.validate();
      if (this.formTitleValid) {
        if (this.index === "country") {
          this.campaign.campaignTargets[this.index] = this.valueToEdit;
        } else {
          this.campaign[this.index] = this.valueToEdit;
        }
        this.update = false;
      }
    },
    handleEdit({ val, index, title }) {
      this.title = title;
      this.index = index;
      this.valueToEdit = val;
      this.update = true;
    },
    async getAdsData(data) {
      this.compaignData.name = this.campaign.name;
      this.compaignData.url = this.campaign.url;
      this.headlines = data.headlines;
      this.descriptions = data.descriptions;
    },
    handleClickBack() {
      this.$router.replace("advertising-goals");
      this.cancel = false;
    },
    handleSaveChanges() {
      this.formAdOptions = false;
    },
    handleClickCancel() {
      this.setDefaultAdsData();
      this.formAdOptions = false;
      this.validatedText = "OK";
    },
    handleCancelModal() {
      this.cancel = false;
    },
    handleClickCancelUpdate() {
      this.getAdsData();
      this.update = false;
    },
    editItem(val) {
      this.form = true;
      this.selectedItem = val;
    },
    async validateAds() {
      const headlines = this.headlines.map((item) => item.title);
      const descriptions = this.descriptions.map((item) => item.title);
      this.isSaving = true;
      try {
        await campaignService.validateAds({
          headlines,
          descriptions,
        });
        this.validatedText = "OK";
        this.isValidated = true;
        this.formAdOptions = false;
      } catch (e) {
        const errCode = e.response?.data.errorCode;
        const errorReasons = e.response?.data?.policyErrorFields;
        const errorTopic = e.response?.data?.topic;
        this.errorReasons = errorReasons;
        this.errorTopic = errorTopic;
        this.validatedText = errCode || this.$t("something_wrong");
        this.$notify({
          group: "foo",
          type: "error",
          title: this.$t("something_wrong"),
        });
      }
      this.isSaving = false;
    },
    async handleClickSave() {
      const userId = localStorage.getItem("userId");
      const campaign = localStorage.getItem("campaign");
      const parseToObj = JSON.parse(campaign);
      const adHeadlines = this.headlines?.map((item) => item.title);
      const adDescriptions = this.descriptions?.map((item) => item.title);
      localStorage.setItem(
        "campaign",
        JSON.stringify({
          ...parseToObj,
          userId: userId,
          name: this.campaign.name,
          adOptions: {
            headlines: adHeadlines,
            descriptions: adDescriptions,
          },
        })
      );
      this.isGettingUserInfo = true;
      try {
        const { data } = await authService.getUserInfo();
        const { active, activePlanID, annual } = data;
        if (active) {
          localStorage.setItem(
            "campaign",
            JSON.stringify({
              ...parseToObj,
              userId: userId,
              name: this.campaign.name,
              adOptions: {
                headlines: adHeadlines,
                descriptions: adDescriptions,
              },
              planId: activePlanID,
              planType: annual ? "annualFee" : "monthlyFee",
              isPlanChoosingFirst: false,
            })
          );
          await this.$router.replace("set-budget-payment");
        } else {
          await this.$router.replace("choose-plan");
        }
      } catch (e) {
        console.log(e);
      }
      this.isGettingUserInfo = false;
    },
    cancelCampaignNameUpdating() {
      this.isOpenChangeNameModal = false;
    },
    saveLocationChanges(data) {
      const campaign = localStorage.getItem("campaign");
      const parseToObj = JSON.parse(campaign);
      localStorage.setItem(
        "campaign",
        JSON.stringify({
          ...parseToObj,
          ...data,
        })
      );
      this.campaign = {
        ...this.campaign,
        ...data,
      };
      this.isOpenChangeLocationModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/style/style.scss";
.mobile-edit-btn {
  display: none;
}
@media screen and (max-width: 948px) {
  .mobile-vision {
    flex-direction: column-reverse;
  }
  .col-md-3 > .edit-btn {
    display: none !important;
  }
  .vision {
    padding-right: 0px !important;
  }
  .mobile-edit-btn {
    display: unset !important;
  }
}
.edit-btn {
  box-shadow: none;
  background-color: transparent !important;
  text-transform: capitalize;
}

.router-link {
  color: black;
  font-size: 16px;
}

.list-item {
  .headline {
    color: #0057ff;
    font-size: 14px;
  }

  p {
    font-size: 15px;
  }
}

.approve-btn {
  width: 100%;
}
.review-titles {
  font-family: "Raleway bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.review-description {
  font-family: "Raleway regular", sans-serif;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  color: #11263c;
}
</style>
